import React, { useState } from "react";
import MobilRightMenuSlider from "@mui/material/Drawer";
import { Link } from "react-router-dom";
//import { FaGithub, FaLinkedinIn, FaFacebook } from "react-icons/fa";
import { SocialIcon } from "react-social-icons";
import {
  AppBar,
  Toolbar,
  ListItem,
  IconButton,
  ListItemText,
  Avatar,
  Divider,
  List,
  Typography,
  Box,
  ListItemIcon,
  Stack,
} from "@mui/material";

import { AssignmentInd, Home, Apps, ContactMail } from "@mui/icons-material";
import Face5Icon from "@mui/icons-material/Face5";
import MenuIcon from "@mui/icons-material/Menu";
import avatar from "../omakuva2_edited.jpg";

const menuItems = [
  {
    listIcon: (
      <Home sx={{ "& :hover": { color: "tomato" }, fontSize: "28px" }} />
    ),
    listText: "Home",
    listPath: "/",
  },
  {
    listIcon: (
      <AssignmentInd
        sx={{ "& :hover": { color: "tomato" }, fontSize: "28px" }}
      />
    ),
    listText: "Resume",
    listPath: "/resume",
  },
  {
    listIcon: (
      <Apps sx={{ "& :hover": { color: "tomato" }, fontSize: "28px" }} />
    ),
    listText: "Portfolio",
    listPath: "/portfolio",
  },
  {
    listIcon: (
      <ContactMail sx={{ "& :hover": { color: "tomato" }, fontSize: "28px" }} />
    ),
    listText: "Contact",
    listPath: "/contact",
  },
  {
    listIcon: (
      <Face5Icon sx={{ "& :hover": { color: "tomato" }, fontSize: "28px" }} />
    ),
    listText: "About",
    listPath: "/about",
  },
];

const Navbar = () => {
  const [state, setState] = useState({
    right: false,
  });

  const toggleSlider = (slider, open) => () => {
    setState({ ...state, [slider]: open });
  };

  const sideList = (slider) => (
    // Sidebar -menu
    <Box
      component="div"
      style={{ width: 265, background: "#731317", height: "100%" }}
      onClick={toggleSlider(slider, false)}
    >
      <Avatar
        src={avatar}
        alt="Riku Ovaskainen"
        style={{
          display: "block",
          margin: "0.5rem auto",
          width: "104px",
          height: "104px",
        }}
      />
      <Divider />
      <List>
        {menuItems.map((lsItem, key) => (
          <ListItem button key={key} component={Link} to={lsItem.listPath}>
            <ListItemIcon style={{ color: "tan" }}>
              {lsItem.listIcon}
            </ListItemIcon>
            <ListItemText primary={lsItem.listText} style={{ color: "tan" }} />
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <>
      <Box component="nav">
        <AppBar position="static" style={{ background: "#222" }}>
          <Toolbar>
            <IconButton onClick={toggleSlider("right", true)}>
              <MenuIcon style={{ color: "tomato", fontSize: "32px" }} />
            </IconButton>
            <Typography variant="h5" style={{ color: "tan", flexGrow: 1 }}>
              Portfolio
            </Typography>
            <Box
              sx={{
                "& :hover": { color: "tomato" },
                flexGrow: 1,
                display: { xs: "none", sm: "block" },
              }}
            >
              <List
                sx={{
                  /* "& :hover" : { color: "tomato"}, */
                  align: "flex-end",
                  display: "flex",
                  justifyContent: "flex-end",
                  width: "100%",
                }}
              >
                <Stack direction="row" spacing={0} >
                  {menuItems.map((lsItem, key) => (
                    <ListItem
                      style={{ color: "tan" }}
                      button
                      key={key}
                      component={Link}
                      to={lsItem.listPath}
                      sx={{"&:hover": { transform: "scale3d(1.05, 1.05, 1)" }}}
                    >
                      <ListItemIcon style={{ color: "tan" }}>
                        {lsItem.listIcon}
                      </ListItemIcon>
                      <ListItemText
                        primary={lsItem.listText}
                        style={{ color: "tan" }}
                      />
                    </ListItem>
                  ))}
                </Stack>
              </List>
            </Box>
          </Toolbar>

          <MobilRightMenuSlider
            anchor="right"
            open={state.right}
            onClose={toggleSlider("right", false)}
          >
            {sideList("right")}
            <div
              style={{
                display: "flex",
                backgroundColor: "#d9232b",
                padding: "10px",
                margin: "0px",
                justifyContent: "space-around",
              }}
            >
              <SocialIcon
                network="github"
                url="https://github.com/Risseli"
                style={{ height: 35, width: 35 }}
              />
              <SocialIcon
                network="linkedin"
                url="https://www.linkedin.com/in/rikuovaskainen/"
                style={{ height: 35, width: 35 }}
              />
              <SocialIcon
                network="facebook"
                url="https://www.facebook.com/riku.ovaskainen/"
                style={{ height: 35, width: 35 }}
              />

              {/* <FaGithub fontSize={35} color="white" url="https://github.com/Risseli"/>
              <FaLinkedinIn fontSize={35} color="white" url="https://www.linkedin.com/in/rikuovaskainen/"/>
              <FaFacebook fontSize={35} color="white" url="https://www.facebook.com/riku.ovaskainen/"/> */}
            </div>
          </MobilRightMenuSlider>
        </AppBar>
      </Box>
    </>
  );
};

export default Navbar;
