let timelineElements = [
  {
    id: 0,
    title: "Service desk technician at Voimatel Oy",
    location: "Kuopio",
    description:
      "Customer service, ticketing, coordinating prioritization of tasks, troubleshooting, etc.",
    buttonText: "view details",
    date: "May 2024 - Present",
    icon: "work",
  },
  {
    id: 1,
    title: "Mobile network field technician at Voimatel Oy",
    location: "Kuopio",
    description:
      "Base station installations and commissioning, mobile fault repair, tranmission equipment installations, maintenance and troubleshooting of equipment rooms including electrical work. Disturbance measurements and coverage mapping, installation planning, etc.",
    buttonText: "view details",
    date: "August 2011 - May 2024",
    icon: "work",
  },
  {
    id: 2,
    title: "Savonia AMK, Bachelor of Engineering, IT",
    location: "Kuopio",
    description: "Bachelor of Engineering, IT",
    buttonText: "view details",
    date: "August 2022 - Present",
    icon: "school",
  },
  {
    id: 3,
    title: "Savon ammattioppilaitos",
    location: "Kuopio",
    description: "Electric and automation",
    buttonText: "view details",
    date: "August 2017 - May 2019",
    icon: "school",
  },
  {
    id: 4,
    title: "Radio network specialist at Digita Oy",
    location: "Kuopio",
    description:
      "Mobile network installations and fault repair, DVB-T network troubleshooting.",
    buttonText: "view details",
    date: "August 2010 - June 2011",
    icon: "work",
  },
  {
    id: 5,
    title: "Mobile service specialist at Lemminkäinen Oy",
    location: "Vantaa",
    description:
      "Mobile basestation installations and fault repair. Ivolving indoors and outdoor installations. Also including rectifier,battery and transmission link installations.",
    buttonText: "view details",
    date: "December 2008 - July 2010",
    icon: "work",
  },
  {
    id: 6,
    title: "Technical intern at Digita Oy",
    location: "Helsinki",
    description: "Technical intern at Digita network installations unit. Installation of mobile networks, cabling, antenna and mobile base station installations.",
    buttonText: "view details",
    date: "June 2008 - November 2008",
    icon: "work",
  },
  {
    id: 7,
    title: "Helsingin tekniikan alan ammattioppilaitos, HELTECH",
    location: "Helsinki",
    description: "Electric and information technology",
    buttonText: "view details",
    date: "August 2006 - May 2008",
    icon: "school",
  },
  {
    id: 8,
    title: "Ceramic worker, Arabia Oy",
    location: "Helsinki",
    description: "Making ceramic products, glazing, firing, etc.",
    buttonText: "view details",
    date: "February 2005 - september 2008",
    icon: "work",
  },
  {
    id: 9,
    title: "Military service",
    location: "Kontiolahti",
    description: "9 months service, military police",
    buttonText: "view details",
    date: "January 2003 - September 2003",
    icon: "school",
  },
  {
    id: 10,
    title: "Juuan lukio",
    location: "Juuka",
    description: "High school",
    buttonText: "view details",
    date: "August 1999 - May 2002",
    icon: "school",
  },
  {
    id: 11,
    title: "Born",
    location: "Joensuu",
    description: "Born in Joensuu, Finland",
    buttonText: "",
    date: "november 1983",
    icon: "",
  },
];

export default timelineElements;
