import React from "react";
import { Grid } from "@mui/material";
import { SocialIcon } from "react-social-icons";

const Footer = () => {
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      spacing={2}
      style={{
        width: "100%",
        color: "tan",
        position: "fixed",
        left: "0",
        bottom: "0",
        textAlign: "center",
        backgroundColor: "transparent",
        zIndex: "1",
        marginTop: "20px",
      }}
    >
      <ul style={{ listStyleType: "none" }}>
        <li style={{ color: "tan", display: "inline-block", padding: "10px"  }}>
          <SocialIcon url="https://github.com/Risseli" bgColor="tan" fgColor="black" />
        </li>
        <li style={{ color: "tan", display: "inline-block", padding: "10px" }}>
          <SocialIcon url="https://www.linkedin.com/in/rikuovaskainen" />
        </li>
        <li style={{ color: "tan", display: "inline-block", padding: "10px" }}>
          <SocialIcon url="https://www.facebook.com/riku.ovaskainen/" />
        </li>
      </ul>
      
     
    </Grid>
    
  );
};

export default Footer;
