import Navbar from "./Navbar";
import {
  Box,
  Grid,
  Card,
  CardActions,
  CardActionArea,
  CardContent,
  CardMedia,
  Button,
  Typography,
} from "@mui/material";
import project1 from "../imagesfolder/react2.jpg";
import project2 from "../imagesfolder/python.jpg";
//import project3 from "../imagesfolder/iot.jpg";
//import project4 from "../imagesfolder/PowerBI.jpg";
import project5 from "../imagesfolder/UIUX.jpg";
import React, { useState } from "react";
import ModalVideo from "react-modal-video";
import "react-modal-video/css/modal-video.css";


const styles = {
  mainContainer: {
    background: "#233",
    height: "100%",
    position: "relative",
  },
  cardContainer: {
    maxWidth: 345,
    margin: "5rem auto",
  },
};

const Portfolio = () => {
  // use state to open the modal
  const [isOpen, setOpen] = useState(false);
  const [videoId, setVideoId] = useState(null);

  const handleOpen = (id) => {
    setOpen(true);
    setVideoId(id);
  };

  const handleClose = () => {
    setOpen(false);
    setVideoId(null);
  };

  return (
    <Box component="div">
      <Navbar />
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        className="mainContainer"
        style={styles.mainContainer}
      >
        <ModalVideo
          channel="youtube"
          youtube={{ mute: 0, autoplay: 0 }}
          isOpen={isOpen}
          videoId={videoId}
          onClose={() => handleClose()}
          className="modal-video"
        />

        {/* Project 1 */}
        <Grid item xs={12} sm={8} md={6}>
          <Card className="cardContainer" style={styles.cardContainer}>
            <CardActionArea>
              <CardMedia
                component="img"
                alt="React.js Project"
                height="140"
                image={project1}
              ></CardMedia>
            </CardActionArea>
            <CardContent>
              <Typography
                gutterBottom
                variant="h5"
                color="textSecondary"
                component="p"
              >
                Portfolio Website
              </Typography>
              <Typography variant="body2">
                This Portfolio website is made with React.js and Material-UI. It
                is a responsive website that looks good on all devices.{" "}
                <span style={{ color: "red" }}>
                  This is still a work in progress.
                </span>
              </Typography>
            </CardContent>
            <CardActions>
              {/* <Button size="small" color="primary">
                Share
              </Button>
              <Button size="small" color="primary">
                Live Demo
              </Button> */}
            </CardActions>
          </Card>
        </Grid>
        {/* Project 2 */}
        <Grid item xs={12} sm={8} md={6}>
          <Card className="cardContainer" style={styles.cardContainer}>
            <CardActionArea>
              <CardMedia
                component="img"
                alt="React.js Project"
                height="140"
                image={project2}
              ></CardMedia>
            </CardActionArea>
            <CardContent>
              <Typography
                gutterBottom
                variant="h5"
                color="textSecondary"
                component="p"
              >
                Simple calculator
              </Typography>
              <Typography variant="body2">
                Small Python calculator made with Tkinter. It can do basic math
                operations like addition, subtraction, multiplication, and
                division.
              </Typography>
            </CardContent>
            <CardActions>
              {/* <Button size="small" color="primary">
                                Share
                            </Button> */}
              <Button
                size="small"
                color="primary"
                onClick={() => handleOpen("dkwjXr-SZ0E")}
              >
                Live Demo
              </Button>
            </CardActions>
          </Card>
        </Grid>
        {/* Project 3 */}
        <Grid item xs={12} sm={8} md={6}>
          <Card className="cardContainer" style={styles.cardContainer}>
            <CardActionArea>
              <CardMedia
                component="img"
                alt="React.js Project"
                height="140"
                image={project5}
              ></CardMedia>
            </CardActionArea>
            <CardContent>
              <Typography
                gutterBottom
                variant="h5"
                color="textSecondary"
                component="p"
              >
                School Project Tic Tac Toe
              </Typography>
              <Typography variant="body2">
                Ahh, the very first coding project in C# Winforms.
              </Typography>
            </CardContent>
            <CardActions>
              <Button
                size="small"
                color="primary"
                onClick={() => handleOpen("65SRa0gEYA0")}
              >
                Live Demo
              </Button>
            </CardActions>
          </Card>
        </Grid>

        
        {/* Project 4 */}
        {/* <Grid item xs={12} sm={8} md={6}>
          <Card className="cardContainer" style={styles.cardContainer}>
            <CardActionArea>
              <CardMedia
                component="img"
                alt="React.js Project"
                height="140"
                image={project4}
              ></CardMedia>
            </CardActionArea>
            <CardContent>
              <Typography
                gutterBottom
                variant="h5"
                color="textSecondary"
                component="p"
              >
                Project 4
              </Typography>
              <Typography variant="body2">
                This is some things i made during my Power BI course.
                <span style={{ color: "red" }}>
                  This is still a work in progress.
                </span>
              </Typography>
            </CardContent>
            <CardActions>
              <Button size="small" color="primary">
                Share
              </Button>
              <Button size="small" color="primary">
                Live Demo
              </Button>
            </CardActions>
          </Card>
        </Grid>
        {/* Project 5 */}
        {/* <Grid item xs={12} sm={8} md={6}>
          <Card className="cardContainer" style={styles.cardContainer}>
            <CardActionArea>
              <CardMedia
                component="img"
                alt="React.js Project"
                height="140"
                image={project5}
              ></CardMedia>
            </CardActionArea>
            <CardContent>
              <Typography
                gutterBottom
                variant="h5"
                color="textSecondary"
                component="p"
              >
                Project 5
              </Typography>
              <Typography variant="body2">
                School project in UI/UX design course.
                <span style={{ color: "red" }}>
                  This is still a work in progress.
                </span>
              </Typography>
            </CardContent>
            <CardActions>
              <Button size="small" color="primary">
                Share
              </Button>
              <Button size="small" color="primary">
                Live Demo
              </Button>
            </CardActions>
          </Card>
        </Grid>  */}

        {/* Project 6 */}

        {/* <Grid item xs={12} sm={8} md={6}>
          <Card className="cardContainer" style={styles.cardContainer}>
            <CardActionArea>
              <CardMedia
                component="img"
                alt="React.js Project"
                height="140"
                image={project5}
              ></CardMedia>
            </CardActionArea>
            <CardContent>
              <Typography
                gutterBottom
                variant="h5"
                color="textSecondary"
                component="p"
              >
                Project 5 - Tic Tac Toe
              </Typography>
              <Typography variant="body2">
                Ahh, the very first coding project in C# Winforms.
              </Typography>
            </CardContent>
            <CardActions>
              <Button
                size="small"
                color="primary"
                onClick={() => handleOpen("65SRa0gEYA0")}
              >
                Live Demo
              </Button>
            </CardActions>
          </Card>
        </Grid> */}
      </Grid>
    </Box>
  );
};

export default Portfolio;
