import React from "react";
import { Typography, Avatar, Grid, Box } from "@mui/material";
import { ReactTyped } from "react-typed";
import avatar from "../omakuva2_edited.jpg";
import Footer from "./Footer";

const Header = () => {
  return (
    <>
      <Box
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%,-50%)",
          width: "100vw",
          textAlign: "center",
          zIndex: "1",
        }}
      >
        <Grid container justifyContent="center" alignItems="center" spacing={0}>
          <Avatar
            src={avatar}
            alt="avatar"
            style={{ width: "150px", height: "150px", marginBottom: "10px" }}
          />
        </Grid>

        <Typography variant="h4">
          <ReactTyped
            strings={["Riku Ovaskainen"]}
            typeSpeed={40}
            style={{ color: "tomato" }}
          />
        </Typography>
        <br />
        <Typography variant="h5">
          <ReactTyped
            strings={["Web developement", "Networking", "Mobile networks"]}
            typeSpeed={40}
            backSpeed={60}
            loop
            style={{ color: "tan", marginBottom: "10px" }}
          />
        </Typography>
      </Box>
      <Footer/>
    </>
  );
};

export default Header;
