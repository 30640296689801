import React from "react";
import { Typography, Box } from "@mui/material";
import Navbar from "./Navbar";
import SchoolIcon from "@mui/icons-material/School";
import WorkIcon from "@mui/icons-material/Work";
import timelineElements from "./TimelineElements";

import "./Resume.css";
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineOppositeContent,
} from "@mui/lab";
import { useEffect, useState } from "react";

// function to see if the window is greater than 512px, then return true

const Resume = () => {
  const [width, setWidth]   = useState(window.innerWidth);
  
  const updateDimensions = () => {
      setWidth(window.innerWidth);
     
  }
  useEffect(() => {
      window.addEventListener("resize", updateDimensions);
      return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  return (
    <>
    <div className="mainContainer">
      <Navbar />
      <Box component="header">
        <Typography variant="h4" textAlign="center" color="tomato">
          Experience
        </Typography>
        <Box component="div">
          <Timeline position="alternate">
            {timelineElements.map((element) => (
              <TimelineItem key={element.id}>
                <TimelineOppositeContent className="timelineOppositeContent" sx={{ "&:hover": { transform: "scale3d(1.05, 1.05, 1)" } }}>
                  <Typography variant="body2" color="white" sx={{ marginTop: "15px" }}>
                    {element.date}
                  </Typography>
                </TimelineOppositeContent>
                <TimelineSeparator className="timelineSeparator">
                  <TimelineDot className="timelineDot" sx={{ background: "white", marginLeft: "20px", marginRight: "20px" }}>
                    {element.icon === "work" ? <WorkIcon sx={{ color: "green" }} /> : <SchoolIcon sx={{ color: "orange" }} />}
                  </TimelineDot>
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent
                
                  sx={{
                    border: "solid black 1px",
                    background: "#2d3e3e",
                    borderRadius: "7px",
                    marginTop: "20px",
                    "&:hover": { transform: "scale3d(1.05, 1.05, 1)" }
                  }}
                >
                  {width < 514 ? (
                    <>
                      <Typography variant="body2" color="white" sx={{ marginTop: "30px" }}>
                        {element.icon === "work" ? <WorkIcon sx={{ color: "green" }} /> : <SchoolIcon sx={{ color: "orange" }} />}
                        <br />
                        {element.date}
                      </Typography>
                    </>
                  ) : null}
                  <Typography variant="h6" component="span" color="white">
                    {element.title}
                  </Typography>
                  <Typography color="orange">{element.location}</Typography>
                  <Typography color="tan">{element.description}</Typography>
                  <a href="your-url-here" style={{ color: "white", textDecoration: "none" }}>
                    {/* {element.buttonText} */}
                  </a>
                </TimelineContent>
              </TimelineItem>
            ))}
          </Timeline>
        </Box>
      </Box>
    </div>
    </>
  );
};

export default Resume;
