import React from "react";
import avatar from "../imagesfolder/omakuva.jpg";
import { Grid, Typography, useMediaQuery } from "@mui/material";
import Navbar from "./Navbar";


const About = () => {
  const isSmallScreen = useMediaQuery("(max-width:600px)");

  return (
    <>
      <Navbar />
      <Grid
        container
        spacing={0}
        backgroundColor="#233"
        display={"flex"}
        flexWrap={isSmallScreen ? "wrap" : "nowrap"}
      >
        {/* First Grid item */}
        <Grid
          item
          container
          xs={12}
          sm={12}
          md={6}
          lg={6}
          xl={6}
          style={{
            height: isSmallScreen ? "80%" : "100vh",
            position: "relative",
            marginBottom: "30px",
          }}
        >
          <img
            src={avatar}
            alt="Avatar"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "scale-down",
            }}
          />
        </Grid>

        {/* Second Grid item */}
        <Grid
          item
          justifyContent="start"
          alignItems="center"
          position="relative"
          display={"flex"}
          xs={isSmallScreen ? 12 : 1}
          sm={1}
          md={1}
          lg={1}
          xl={1}
          style={{
            height: isSmallScreen ? "1vh" : "100vh",
            position: "relative",
            width: isSmallScreen ? "100%" : "50%",
            marginLeft: "10px",
          }}
        >
          <Typography
            variant={isSmallScreen ? "h1" : "h2"}
            component="p"
            sx={{
              writingMode: isSmallScreen ? "horizontal-rl" : "vertical-rl",
              whiteSpace: "nowrap",
              marginTop: "10px",
              fontSize: {
                xs: "20px",
                sm: "24px",
                md: "28px",
                lg: "32px",
                xl: "36px",
              },
              color: "tomato",
              marginBottom: "10px",
            }}
          >
            Just a normal day at the office..
            <br />
            <span style={{ fontSize: "14px" }}>Concentration 100%..</span>
          </Typography>
        </Grid>

        {/* third Grid item */}
        <Grid
          item
          container
          alignContent={"center"}
          xs={12}
          sm={6}
          md={6}
          lg={6}
          xl={6}
          color="white"
          style={{
            marginTop: "30px",
            marginLeft: "10px",
            marginBottom: "10px",
            marginRight: "20px",
          }}
        >
          <Typography variant={isSmallScreen ? "h3" : "h1"}>
            Something about me..
          </Typography>
          <br />
          <Typography variant="body1" color="tan">
          I'm currently on study leave from my job as a mobile field technician at Voimatel. Computers and technology have been my passion for as long as I can remember. A couple of years ago, I made the decision to further my studies in IT, and I've been captivated by this ever-evolving industry ever since. My days are filled with my part-time job, studies, taking care of my two boys, our cat, our dog, and of course, spending time with my wife. However, amidst the busyness, I still manage to carve out time for my personal projects and to dive deeper into the world of IT and programming.
            <br />
            <br />
            But life isn't just about work and learning. I also make sure to prioritize my health by spending time in the gym, and occasionally unwind by watching movies or series. I'm a coffee enthusiast, a lifelong learner, and admittedly, a bit of a geek. Recently, I've found myself developing an interest in security and leadership as well.
            <br />
            <br />
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default About;
