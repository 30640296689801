import React from "react";
import { useState } from "react";
import emailjs from "@emailjs/browser";
import { useRef } from "react";
import Modal from "@mui/material/Modal";
import { Typography, Box, Grid } from "@mui/material";

import Navbar from "./Navbar";
import Footer from "./Footer";

const Contact = () => {
  const form = useRef();
  const [open, setOpen] = useState(false);
  const [openWarning, setOpenWarning] = useState(false);
  const [error, setError] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setOpenWarning(false);
    setError(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  // style for modal
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    color: "black",
    boxShadow: 24,
    p: 4,
  };

  const sendEmail = (e) => {
    e.preventDefault();

    if (
      form.current.from_name.value === "" ||
      form.current.to_name.value === "" ||
      form.current.message.value === ""
    ) {
      setOpenWarning(true);
      return;
    } else {
      setOpenWarning(false);
    }

    emailjs
      .sendForm("service_9p1grrz", "template_ia3yaui", form.current, {
        publicKey: "KI7d6aOWG3pPXsyBB",
      })
      .then(
        () => {
          console.log("SUCCESS!");
          //form.current.reset();
          // window.alert("Message sent successfully! I will get back to you soon.");
          handleOpen();
        },
        (error) => {
          console.log("FAILED...", error.text);
          setError(true);
        }
      );
  };

  return (
    <>
      <Navbar />
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        spacing={2}
        style={{
          width: "100%",
          color: "tan",
          textAlign: "center",
          backgroundColor: "transparent",
          zIndex: "1",
          marginTop: "20px",
        }}
      >
        {(open || openWarning) && (
          <Modal
            open={open || openWarning || error}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                {open
                  ? "Thank you for reaching out! 🙏🏼"
                  : error
                  ? "Something went wrong.😔 "
                  : "Empty input field"}
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                {open
                  ? "Message sent successfully! I will get back to you soon."
                  : error
                  ? "Message was not sent. Try again later."
                  : "Please fill all input fields."}
              </Typography>
            </Box>
          </Modal>
        )}

        <form
          ref={form}
          onSubmit={sendEmail}
          style={{
            textAlign: "center",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ul style={{ listStyleType: "none" }}>
            <h1 style={{ color: "white" }}>Reach me out..!</h1>
            <li style={{ textAlign: "center" }}>
              <label style={{ display: "block", color: "tan" }}>Name</label>
              <input
                type="text"
                name="to_name"
                style={{
                  display: "block",
                  margin: "0 auto",
                  width: "200px",
                  border: "2px solid tan",
                }}
              />
            </li>
            <br />
            <li style={{ textAlign: "center" }}>
              <label style={{ display: "block", color: "tan" }}>Email</label>
              <input
                type="email"
                name="from_name"
                style={{
                  display: "block",
                  margin: "0 auto",
                  width: "200px",
                  border: "2px solid tan",
                }}
              />
            </li>
            <br />
            <li style={{ textAlign: "center" }}>
              <label style={{ display: "block", color: "tan" }}>Message</label>
              <textarea
                name="message"
                style={{
                  display: "block",
                  margin: "0 auto",
                  width: "200px",
                  border: "2px solid tan",
                  height: "100px",
                }}
              />
            </li>
            <br /> <br />
            <li style={{ textAlign: "center" }}>
              <input
                type="submit"
                value="Send"
                style={{
                  display: "block",
                  margin: "0 auto",
                  width: "200px",
                  backgroundColor: "tomato",
                  color: "#233",
                  border: "2px solid tomato",
                  transition:
                    "background-color 0.3s, color 0.3s, border-color 0.3s",
                }}
                onMouseEnter={(e) => {
                  e.target.style.backgroundColor = "#FF6347"; // Darken the background color on hover
                  e.target.style.color = "#fff"; // Change text color to white on hover
                  e.target.style.scale = "1.1"; // Change text color to white on hover
                }}
                onMouseLeave={(e) => {
                  e.target.style.backgroundColor = "tomato"; // Restore original background color
                  e.target.style.color = "#233"; // Restore original text color
                  e.target.style.scale = "1.0"; // Restore original text color
                }}
              />
            </li>
          </ul>
        </form>
      </Grid>

      <Footer />
    </>
  );
};

export default Contact;
