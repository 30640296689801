import "./App.css";
import React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { Route, Routes } from "react-router-dom";
import Home from "./components/index";
import Resume from "./components/Resume";
import Portfolio from "./components/Portfolio";
import Contact from "./components/Contact";
import About from "./components/About";

function App() {
  return (
    <>
      <div className="App">
        <>
          <CssBaseline>
            <Routes>
              <Route exact path="/" element={<Home />}/>
              <Route path="/resume" element={<Resume />} />
              <Route path="/portfolio" element={<Portfolio />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/about" element={<About />} />
            </Routes>
          </CssBaseline>
        </>
        <header className="App-header"></header>
      </div>
    </>
  );
}

export default App;
